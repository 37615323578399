// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :loading="loading"
        layout="vertical"
        @finish="handleSubmit">
        <a-form-item name="newname" ref="newname">
          <template #label>
            <tooltip-label :title="$t('label.name')" :tooltip="apiParams.newname.description"/>
          </template>
          <a-input
            v-model:value="form.newname"
            :placeholder="apiParams.newname.description" />
        </a-form-item>
        <a-form-item name="networkdomain" ref="networkdomain">
          <template #label>
            <tooltip-label :title="$t('label.networkdomain')" :tooltip="apiParams.networkdomain.description"/>
          </template>
          <a-input
            v-model:value="form.networkdomain"
            :placeholder="apiParams.networkdomain.description" />
        </a-form-item>
        <a-form-item v-if="'listRoles' in $store.getters.apis" name="newrole" ref="newrole">
          <template #label>
            <tooltip-label :title="$t('label.role')" :tooltip="apiParams.roleid.description"/>
          </template>
          <a-select
            v-model:value="form.newrole"
            :placeholder="apiParams.roleid.description">
            <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="'listProjects' in $store.getters.apis">
          <template #label>
            <tooltip-label :title="$t('label.defaultproject')" :tooltip="apiParams.defaultprojectid.description"/>
          </template>
          <select-project-view
            :resource="resource"
            @project-selected="setDefaultProject"/>
        </a-form-item>
        <div :span="24" class="action-button">
          <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
          <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>
<script>

import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import SelectProjectView from '@/views/project/iam/SelectProjectView.vue'

export default {
  name: 'EditAccount',
  components: {
    SelectProjectView,
    TooltipLabel
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      accountId: null,
      roles: []
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('updateAccount')
  },
  created () {
    this.initForm()
    this.fetchData()
    if ('listRoles' in this.$store.getters.apis) {
      this.fetchRoles()
    }
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({})
    },
    fetchData () {
      this.accountId = this.$route.params.id || null
      this.fillEditFormFieldValues()
    },
    fillEditFormFieldValues () {
      const form = this.form
      if (this.resource.networkdomain) {
        form.networkdomain = this.resource.networkdomain
      }
      form.newname = this.resource.name
      form.newrole = this.resource.roleid
    },
    setDefaultProject (project) {
      this.defaultprojectid = project?.id || ''
    },
    fetchRoles () {
      this.loading = true
      api('listRoles').then(response => {
        this.roles = response.listrolesresponse.role
      }).catch(error => {
        this.$notification.error({
          message: this.$t('message.request.failed'),
          description: (error.response && error.response.headers && error.response.headers['x-description']) || error.message,
          duration: 0
        })
      }).finally(() => {
        this.loading = false
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)
        this.loading = true
        const params = {
          id: this.accountId,
          newname: values.newname,
          networkdomain: values.networkdomain
        }
        if (this.defaultprojectid !== undefined) {
          params.defaultprojectid = this.defaultprojectid
        }
        var changeRole = false
        if (values.newrole && values.newrole !== this.resource.roleid) {
          params.roleId = values.newrole
          changeRole = true
        }

        api('updateAccount', params).then(response => {
          this.$emit('refresh-data')
          this.$notification.success({
            message: this.$t('label.edit.account'),
            description: (changeRole)
              ? this.$t('message.success.update.account.rolechanged', { name: response.updateaccountresponse?.account.name })
              : `${this.$t('message.success.update.account')} ${response.updateaccountresponse?.account.name}`
          })
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('message.request.failed'),
            description: (error.response && error.response.headers && error.response.headers['x-description']) || error.message,
            duration: 0
          })
        }).finally(() => {
          this.loading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>

<style scoped lang="less">
.form-layout {
  width: 80vw;
  @media (min-width: 600px) {
    width: 450px;
  }
}
</style>
